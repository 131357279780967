import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/jquery-use",
  "date": "2014-12-30",
  "title": "JQUERY – HOW TO USE",
  "author": "admin",
  "tags": ["development", "javascript", "jquery"],
  "featuredImage": "feature.jpg",
  "excerpt": "In this article we’ll look at how we can use jquery to manipulate, traverse and select DOM elements at ease. Before we start we have to download jquery and load it in your HTML document"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`    <script src="jquery.min.js"></script>
`}</code></pre>
    <h2>{`Changing multiple elements at once`}</h2>
    <p>{`How do we change the text of every `}<inlineCode parentName="p">{`<li>`}</inlineCode>{` in this page?`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h1>Where do you want to go?</h1>
<h2>Travel Destinations</h2>
<p>Plan your next adventure.</p>
<ul id="destinations">
    <li>Rome</li>
    <li>Paris</li>
    <li class='promo'>Rio</li>
</ul>
`}</code></pre>
    <p>{`First, find them and then modify their text.`}</p>
    <p><img parentName="p" {...{
        "src": "https://farm8.staticflickr.com/7560/15957306630_ea20d131c5_o.png",
        "alt": "modify their text"
      }}></img></p>
    <p>{`Let's select list items with the word `}<strong parentName="p">{`Orlando`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`$("li").text("Orlando");
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "https://farm8.staticflickr.com/7537/15522310494_c395d6593c_o.png",
        "alt": "select list items"
      }}></img></p>
    <h2>{`Finding elements by ID and class`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`$('#destinations');
`}</code></pre>
    <h2>{`Changing multiple elements at once`}</h2>
    <p>{`How do we specifically select the `}<inlineCode parentName="p">{`<ul>`}</inlineCode>{` that has a `}<strong parentName="p">{`destinations`}</strong>{` ID?`}</p>
    <p>{`HTML document`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<h1>Where do you want to go?</h1>
<h2>Travel Destinations</h2>
<p>Plan your next adventure.</p>
<ul id="destinations">
    <li>Rome</li>
    <li>Paris</li>
    <li class='promo'>Rio</li>
</ul>   
`}</code></pre>
    <h2>{`Selecting by unique ID`}</h2>
    <p>{`You can find an element by id using the `}<inlineCode parentName="p">{`$('#id')`}</inlineCode>{` format. Notice the `}<strong parentName="p">{`#`}</strong>{` sign, that's what we use to find the id similar to CSS.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`("#destinations");
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "https://farm9.staticflickr.com/8598/15522355974_48678e9c72_o.png",
        "alt": "find an element by id"
      }}></img></p>
    <p>{`How can we select just the `}<inlineCode parentName="p">{`<ul>`}</inlineCode>{` that has a `}<strong parentName="p">{`promo`}</strong>{` class attribute? We have to find it using the class.`}</p>
    <h2>{`Selecting by Class Name`}</h2>
    <p>{`You can find elements by class name using the `}<inlineCode parentName="p">{`$('.class')`}</inlineCode>{` format. Notice the `}<strong parentName="p">{`.`}</strong>{` sign, that's what we use to find the classes similar to CSS.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`$(".promo");
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "https://farm8.staticflickr.com/7475/16118949636_ff2f3c1cf6_o.png",
        "alt": "Selecting by Class Name"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      